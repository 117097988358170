import Vue, { VueConstructor } from 'vue'

Vue.component('v-icon', () => import('../components/v-icon/index.vue'))

export default class Application {
  protected vm: Vue

  constructor(modules: any = [], router: any = null) {
    this.requireIcons()

    if (!Array.isArray(modules)) {
      modules = [modules]
    }

    if (router) {
      this.loadRouter().then(() => {
        this.initVue(modules, { router })
      })
    } else {
      this.initVue(modules)
    }
  }

  private async loadRouter() {
    const router = await import('vue-router')
    Vue.use(router.default)
  }

  private initVue(modules: VueConstructor[], plugins: { router?: any } = {}) {
    this.vm = new Vue({
      name: 'App',
      ...plugins,
      mixins: modules,
      data: {},
    })

    this.vm.$mount('#eg-app')
  }

  private requireIcons() {
    const div = document.createElement('div')
    const body = document.body
    const cssString =
      'border: 0; clip: rect(0 0 0 0); height: 0; overflow: hidden; padding: 0; position: absolute; width: 0;'

    div.style.cssText = cssString
    div.innerHTML = require('!!html-loader!../../img/icons.svg')
    body.insertBefore(div, body.childNodes[0])
  }
}
