import axios from 'axios'

const http = axios.create({
  baseURL: '/',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  timeout: 5000,
})

export class HttpError extends Error {
  url = ''
  payload = ''
  status = 0

  constructor(message: string) {
    super(message)
    this.name = 'HttpError'
  }
}

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const response: any = error.response

    if (response && response.status) {
      const data: any = response.data

      switch (response.status) {
        case 401:
        case 403:
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          break
      }
    }

    return Promise.reject(error)
  },
)

export { http }
